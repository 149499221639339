/* Computer */
.grid_1 { width: 6.5%; } 
.grid_2 { width: 15%; } 
.grid_3 { width: 23.5%; } 
.grid_4 { width: 32%; } 
.grid_5 { width: 40.5%; }
.grid_6 { width: 49%; } 
.grid_7 { width: 57.5%; } 
.grid_8 { width: 66%; } 
.grid_9 { width: 74.5%; } 
.grid_10 { width: 83%; } 
.grid_11 { width: 91.5%; } 
.grid_12 { width: 100%; } 

.grid_1,
.grid_2,
.grid_3,
.grid_4,
.grid_5,
.grid_6,
.grid_7,
.grid_8,
.grid_9,
.grid_10,
.grid_11,
.grid_12 {
	margin: 0 2% 1% 0;
	float: left;
	display: block;
}

.alpha{margin-left:0;}
.omega{margin-right:0;}

.container{
	width: 100%;
	max-width: 960px;
	margin: auto;
	
}



.clear{clear:both;display:block;overflow:hidden;visibility:hidden;width:0;height:0}.clearfix:after{clear:both;content:' ';display:block;font-size:0;line-height:0;visibility:hidden;width:0;height:0}* html .clearfix,*:first-child+html .clearfix{zoom:1}




/* Mobile */
@media screen and (max-width : 480px) {

.grid_1,
.grid_2,
.grid_3,
.grid_4,
.grid_5,
.grid_6,
.grid_7,
.grid_8,
.grid_9,
.grid_10,
.grid_11,
.grid_12 {
	width:100%;
}

}